export const TOP_FEATURES_TITLES_CONFIG = [
  {
    title: '24/7 online bookings',
    description:
      'Allow your customers to book appointments anytime and anywhere, maximising your schedule’s potential.',
    icon: 'Calendar',
  },
  {
    title: 'Easy to use platform',
    description:
      'Say goodbye to complicated software with endless buttons – we keep it sleek and simple, with features you truly need.',
    icon: 'Desktop',
  },
  {
    title: 'Appointment management',
    description:
      'Say goodbye to pen and paper - Pata’s powerful calendar and admin tools make managing your day-to-day a breeze.',
    icon: 'CalendarPerson',
  },
  {
    title: 'Reach out to new customers',
    description: "List your business on Pata's online marketplace to reach new furry clients, locally and beyond.",
    icon: 'Megaphone',
  },
  {
    title: 'No-shows protection',
    description:
      'Secure your bookings with upfront payments and maintain full control over deposits and cancellation fees for each service.',
    icon: 'NoShow',
  },
  {
    title: 'Automated client communications',
    description:
      'Keep clients aware of appointment changes, remind them of upcoming sessions and ask for reviews without lifting a finger.',
    icon: 'TextMessage',
  },
];
